<template>
  <v-container>
    <v-card
      v-if="design && design.base"
      class="elevation-1 ma-2"
      cols="4"
      outlined
    >
      <div class="text-overline mb-4">
        最終更新 {{ design.updated_at }}
      </div>
      <v-row>
        <v-col cols="3">
          <ProjectDesignCard
            v-if="design.base"
            :design="design"
          />
        </v-col>
        <v-col>
          <TargetProfileView
            :compound="design.base"
            :profile="design.targets"
            show-category-name
            show-priority
            priority-read-only
          />
        </v-col>
      </v-row>
      <v-row v-if="design.filters.length !== 0" no-gutters>
        <v-col cols="2" class="text-h6 pl-3">
          Filters
        </v-col>
        <v-col cols="6">
          <v-row
            v-for="(filter, i) in design.filters"
            :key="`filter_${i}`"
            no-gutters
          >
            <v-checkbox
              v-model="selectedFilters"
              :value="filter"
              :label="`${filter.name} (${filter.predicted_value_name})`"
              :disabled="!hasResults"
              dense
              hide-details
            />
          </v-row>
        </v-col>
        <v-col cols="4" align="right" class="pr-4">
          <v-btn
            :disabled="selectedFilters.length == 0 ||
              filteredResults.length == 0"
            @click="downloadFilteredResults"
          >
            <v-icon dark light>mdi-download</v-icon>
            表示の結果ダウンロード
          </v-btn>
        </v-col>
      </v-row>
      <hr>
      <v-row no-gutters class="sticky-row">
        <v-checkbox
          v-model="showStructure"
          class="ma-2"
          label="構造を表示する"
          :disabled="displayMode != 'list'"
        />
        <v-radio-group v-model="displayMode" row class="mt-2">
          <v-radio
            label="表形式"
            value="list"
          />
          <v-radio
            label="タイル形式"
            value="tiles"
            class="ml-10"
          />
        </v-radio-group>
        <v-checkbox
          v-model="showLegend"
          class="ma-2"
          label="ガイドを表示する"
          :disabled="displayMode != 'tiles'"
        />
        <v-spacer />
        <v-btn
          v-if="design.is_downloadable && (design.end_at !== null || design.status === 'Canceled') "
          class="mt-2 mr-4"
          @click="downloadWorkFolder"
        >
          <v-icon dark left>mdi-download</v-icon>
          ワークフォルダ ダウンロード
        </v-btn>
        <v-btn
          class="mt-2 mr-4"
          @click="downloadAllResults"
        >
          <v-icon dark left>mdi-download</v-icon>
          全ての結果ダウンロード
        </v-btn>
        <CancelExecutionButton
          v-if="isCancelableExecution()"
          :execution-id="design.execution"
          execution-type="project_design"
          @refresh="refresh"
        />
      </v-row>
      <ResultList
        v-show="displayMode == 'list'"
        :compounds="filteredResults"
        :profile="design.targets"
        :show-structure="showStructure"
        :id-field="design.result_id_field"
        :filters="filters"
        :is-loading="isLoading"
        :no-data-text="noDataText"
        :prevent-options-update="preventOptionsUpdate"
        :page-count="nbPages"
        :item-count="nbItems"
        :selected-filters="selectedFilters"
        :no-results-to-display="noResultsToDisplay"
        :result-columns="resultColumns"
        :isExecuting="design.end_at === null && design.status !== 'Canceled'"
        @setPreventOptionsUpdate="setPreventOptionsUpdate"
        @updateData="loadProjectDesignResults"
        @createChild="createChildDesign"
      />
      <ResultCompoundTiles
        v-show="displayMode == 'tiles'"
        :compounds="tileResults"
        :profile="design.targets"
        :show-legend="showLegend"
        :filters="filters"
      />
    </v-card>
    <ProjectDesignSettings
      v-else-if="design"
      :project-id-prop="design.project_id"
    />
  </v-container>
</template>

<script>
import router from '@/router';
import CancelExecutionButton from '@/components/CancelExecutionButton.vue';
import ProjectDesignCard from '@/components/design/Project/ProjectDesignCard';
import ProjectDesignSettings from '@/pages/design/ProjectDesignSettings';
import ResultCompoundTiles from
  '@/components/design/Project/ResultCompoundTiles.vue';
import ResultList from '@/components/design/Project/ResultList.vue';
import TargetProfileView from '@/components/design/TargetProfileView';
import { showErrorDialog, showConfirmDialog } from '@/mixins/utils';
import { ITEMS_PER_PAGE_OPTIONS } from '@/env';
import { downloadWorkFolder } from '@/mixins/api_utils';

export default {
  name: 'ProjectDesign',
  components: {
    CancelExecutionButton,
    ResultList,
    ProjectDesignCard,
    ProjectDesignSettings,
    ResultCompoundTiles,
    TargetProfileView
  },
  data() {
    return {
      id: this.$route.params.id,
      design: null,
      showStructure: true,
      showLegend: false,
      results: [],
      filteredResults: [],
      displayMode: 'list',
      isLoading: true,
      sortInfos: [],
      tileResults: [],
      filters: [],
      selectedFilters: [],
      noResultsToDisplay: true,
      hasResults: false,
      noDataText: '',
      noResults: true,
      currentUser: this.$session.get('user'),
      nbPages: 0,
      nbItems: 0,
      preventOptionsUpdate: true,
      isMounted: false,
      activeController: null,
      resultColumns: []
    };
  },
  async mounted() {
    this.checkLoggedIn(this.$session);
    const self = this;
    const params = {
      params: {
        option: true
      }
    };
    await this.api.loadProjectDesign(this.id, params,
      function(design) {
        self.design = design;
        self.filters = self.design.filters.map(f => f.predicted_value_name);
        self.hasResults = self.design.has_results;
        if (design.status === 'Canceled') {
          self.noDataText = '実行はキャンセルされました';
        } else if (design.begin_at === null) {
          self.noDataText = '実行待ちです';
        } else if (design.end_at === null) {
          self.noDataText = '実行中です';
        } else if (self.hasResults) {
          self.noDataText = 'フィルタ条件に一致するデータがありません';
          self.selectedFilters = self.design.filters;
        } else {
          self.noDataText = 'データがありません';
        }
      }, function(error) {
        if (error.response.status !== 401) {
          console.log(error.response.data);
        }
      }
    );

    if (self.hasResults) {
      await this.api.getPDExecutionResultColumns(this.design.execution,
        function(data) {
          self.resultColumns = data.columns;
        }, function() {
          console.log('Failed to fetch result columns');
        }
      );
    }

    await this.loadProjectDesignResults(
      {
        page: 1,
        itemsPerPage: Number(localStorage.getItem('pd-res-ipp')) ||
            ITEMS_PER_PAGE_OPTIONS[0]
      },
      this.selectedFilters.map((filter) => filter.predicted_value_name)
    );
  },
  methods: {
    downloadWorkFolder() {
      this.isLoading = true;
      downloadWorkFolder(this.design.execution, 'project_design_execution');
      this.isLoading = false;
    },
    refresh() {
      const self = this;
      const params = {
        params: {
          option: true
        }
      };
      this.loadProjectDesign(self, this.id, params);
    },
    loadProjectDesign(self, id, params) {
      this.api.loadProjectDesign(id, params,
        function(design) {
          self.design = design;
          self.filters = self.design.filters.map(f => f.predicted_value_name);
          if (design.status === 'Canceled') {
            self.noDataText = '実行はキャンセルされました';
          } else if (design.begin_at === null) {
            self.noDataText = '実行待ちです';
          } else if (design.end_at === null) {
            self.noDataText = '実行中です';
          } else {
            self.noDataText = 'データがありません';
          }
        }, function(error) {
          if (error.response.status !== 401) {
            console.log(error.response.data);
          }
        });
    },
    downloadAllResults() {
      this.api.downloadProjectDesignOutputFile(this.id);
    },
    downloadFilteredResults() {
      const filterNames = this.selectedFilters.map((filter) =>
        filter.predicted_value_name
      );
      this.api.downloadProjectDesignOutputFile(this.id, filterNames);
    },
    async loadProjectDesignResults(dataTableOptions, filterNames, numericFilters) {
      if (this.design.base !== null) {
        const self = this;
        self.isLoading = true;
        // Need a new controller every time a new request is sent
        if (this.activeController !== null) {
          this.activeController.abort();
        }
        this.activeController = new AbortController();
        const signal = this.activeController.signal;

        await this.api.loadProjectDesignResults(
          this.id,
          dataTableOptions,
          filterNames,
          numericFilters,
          signal,
          function(data) {
            self.results = data.results;
            for (const result of data.results) {
              result.structure = result.structure.replace(
                "width='300px'",
                "width='150px'"
              );
              result.structure = result.structure.replace(
                "height='300px'",
                "height='150px'"
              );
            }
            self.tileResults = self.results;
            self.filteredResults = self.results;
            self.noResultsToDisplay = self.results.length === 0;
            self.preventOptionsUpdate = true;
            self.nbPages = data.total_pages;
            self.nbItems = data.count;
            self.isLoading = false;
          }, function(error) {
            self.isLoading = false;
            if (error.status !== null) {
              showErrorDialog(
                '結果を取得できませんでした。',
                'もう一度実行するか、解決しない場合は管理者に知らせてください。'
              );
            }
          });
      }
      this.preventOptionsUpdate = false;
    },
    isCancelableExecution() {
      const isExecutor = this.design.executor === this.currentUser.id;
      const isCancelableStatus = (this.design.end_at === null) &&
                                (this.design.status !== 'Canceled');
      return isExecutor && isCancelableStatus;
    },
    setPreventOptionsUpdate(value) {
      this.preventOptionsUpdate = value;
    },
    createChildDesign(selectedCompound) {
      const self = this;
      if (selectedCompound === null) {
        showErrorDialog(
          '子デザインを作成できません。',
          '子デザインを作成するには、化合物を選択してください。'
        );
        return;
      }
      showConfirmDialog({
        title: '子デザインを作成します。',
        html: '選択した化合物で新しいデザインを作成しますか？',
        approveCB: () => {
          self.api.createChildDesign(
            self.id,
            { 'mol': selectedCompound.mol },
            function(data) {
              router.push({ name: 'ProjectDesign', params: { id: data.id, child: true } });
            }, function(erroe) {
              showErrorDialog(
                'Error',
                'Something went wrong. Contact an administrator.'
              );
            }
          );
        }
      });
    }
  }
};
</script>

<style scoped>
.sticky-row {
  position: sticky;
  top: 48px;
  z-index:9;
  background-color: white;
}
</style>
