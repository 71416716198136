<template>
  <v-data-table
    class="elevation-1"
    :items="workflows"
    item-key="id"
    :headers="headers"
    :loading="isLoading"
    loading-text="ロード中..."
    no-data-text="データがありません。"
    :items-per-page="itemsPerPage"
    fixed-header
    :height="tableHeight"
    sort-by="created_at"
    sort-desc
    multi-sort
    hide-default-footer
    disable-pagination
    :search="search"
    @pagination="onPaginationChange"
  >
    <template #[`item.description`]="{ item }">
      <div class="my-1 description-short">{{ (item.description) }}</div>
    </template>
    <template #[`item.action`]="{ item }">
      <div class="no-wrap">
        <v-btn
          class="ma-2"
          color="primary"
          @click="openWorkflowDetail(item.id)"
        >
          Details &amp; Results
        </v-btn>
        <v-btn
          class="ma-2"
          @click="openWorkflowExecution(item.id)"
        >
          Execute
        </v-btn>
        <v-btn
          v-if="currentUser.is_staff"
          class="ma-2"
          color="error"
          @click="confirmWorkflowDeletion(item)"
        >
          Delete
        </v-btn>
      </div>
    </template>
    <template #top="{ pagination, options, updateOptions }">
      <v-data-footer
        :pagination="pagination"
        :options="options"
        :items-per-page-options="itemsPerPageOptions"
        items-per-page-text="$vuetify.dataTable.itemsPerPageText"
        :show-first-last-page="true"
        @update:options="updateOptions"
      >
        <template #prepend>
          <SearchField
            @input="search = $event"
          />
        </template>
      </v-data-footer>
    </template>
  </v-data-table>
</template>

<script>
import router from '../router';
import SearchField from '@/components/SearchField';
import {
  tableHeightWithMargin,
  showConfirmDialog,
  showSuccessDialog,
  showErrorDialog
} from '@/mixins/utils';
import { ITEMS_PER_PAGE_OPTIONS } from '@/env';

export default {
  name: 'WorkflowTable',
  components: {
    SearchField
  },
  props: {
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Created', value: 'created_at' },
        { text: 'Action', value: 'action', filterable: false, sortable: false }
      ],
      workflows: [],
      isLoading: true,
      itemsPerPage:
        Number(localStorage.getItem('wf-ipp')) ||
        ITEMS_PER_PAGE_OPTIONS[0],
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
      currentUser: this.$session.get('user')
    };
  },
  computed: {
    tableHeight: function() {
      // Createボタン(36px)とそのマージン(12px)を考慮する必要があります
      return tableHeightWithMargin(48);
    }
  },
  mounted() {
    this.loadWorkflows();
  },
  methods: {
    loadWorkflows: function() {
      const self = this;
      this.api.loadWorkflows(function(list) {
        self.workflows = list.map(item => ({
          ...item,
          created_at: item.created_at.split(' ')[0].replace(/-/g, '/')
        }));
        self.isLoading = false;
      }, function(error) {
        self.isLoading = false;
      });
    },
    openWorkflowDetail: function(id) {
      router.push({ name: 'WorkflowEditor', params: { id: id } });
    },
    openWorkflowExecution: function(id) {
      router.push({ name: 'WorkflowExecution', params: { id: id } });
    },
    onPaginationChange(pagination) {
      if (pagination.itemsPerPage) {
        this.itemsPerPage = pagination.itemsPerPage;
        localStorage.setItem('wf-ipp', pagination.itemsPerPage);
      }
    },
    confirmWorkflowDeletion(workflow) {
      const self = this;
      showConfirmDialog({
        title: `Workflow ${workflow.name} will be deleted.`,
        html: 'This operation cannot be undone. Are you sure you want to delete it?',
        approveCB: () => {
          self.api.deleteWorkflow(
            workflow.id,
            function(data) {
              showSuccessDialog('', `Workflow ${workflow.name} was deleted.`);
              self.loadWorkflows();
            },
            function(e) {
              showErrorDialog(
                'An unexpected error occurred',
                `Try again or let an administrator
                          know if the problem persists.`
              );
            }
          );
        }
      });
    }
  }
};
</script>

<style scoped>
.no-wrap {
    white-space: nowrap;
}
</style>
