<template>
  <div class="line">
    <span class="label">RNN Setting:</span>
    <div class="indent border-box">
      <v-form ref="form" @submit.prevent>
        <v-autocomplete
          v-model="selected"
          :items="rnnDirOptions"
          label="Select RNN model"
          item-value="value"
          item-text="label"
          :rules="[v => !!(v && v.length) || 'RNN model is required']"
          @change="changeRnnDir"
          @submit.prevent
        />
      </v-form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RnnVolumeSelection',
  components: {},
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selected: '',
      rnnDirOptions: []
    };
  },
  computed: {},
  watch: {
    value: {
      handler: function() {
        this.setSelected();
      }
    }
  },
  mounted() {
    const self = this;
    this.api.loadRnnDirOptions(function(list) {
      self.rnnDirOptions = list;
      self.setSelected();
    }, function(error) {
      console.log(error);
    });
  },
  methods: {
    changeRnnDir() {
      this.$emit('select', this.selected);
    },
    validatedForm() {
      return this.$refs.form.validate();
    },
    setSelected() {
      const options = this.rnnDirOptions.map(v => v.value);
      if (options.includes(this.value)) {
        this.selected = this.value;
      } else if (options.includes('chembl220k_rand')) {
        this.selected = 'chembl220k_rand';
      } else {
        this.selected = '';
      }
      this.$emit('select', this.selected);
    }
  }
};
</script>

<style scoped>
.line {
  margin-top: 5px;
  margin-bottom: 5px;
}
.label {
    display: inline-block;
}
.border-box {
    padding-left: 10px;
    border-left: solid 1px;
    max-width: 300px;
}
.indent {
    margin-left: 50px;
}
</style>
