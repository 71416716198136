<template>
  <div>
    <v-data-table
      :headers="filteredHeaders"
      :items="modules"
      :loading="isLoading"
      loading-text="ロード中..."
      no-data-text="データがありません。"
      fixed-header
      :height="tableHeight"
      sort-by="updated_at"
      sort-desc
      multi-sort
      class="elevation-1"
      hide-default-footer
      disable-pagination
      :search="search"
    >
      <template #[`item.status`]="{ item }">
        <MLMStatus :model="item" />
      </template>
      <template #[`item.description`]="{ item }">
        <div class="my-1 description-short">{{ (item.description) }}</div>
      </template>
      <template #[`item.action`]="{ item }">
        <v-row class="action-column" no-gutters>
          <v-col :cols="actionColumnCols">
            <LinkIcon
              v-if="item.status == 'Registered'"
              class="no-style-link"
              :to="`/model/${item.id}`"
              tooltip="更新"
              icon="mdi-pencil"
            />
            <LinkIcon
              v-if="item.status == 'Verified'"
              class="no-style-link"
              :to="`/model/${item.id}`"
              tooltip="見る"
              icon="mdi-magnify"
            />
          </v-col>
          <v-col v-if="!staffSettings" :cols="actionColumnCols">
            <LinkIcon
              v-if="['Creation in progress', 'Registered', 'Verified'].includes(item.status)"
              class="no-style-link"
              :to="`/models/${item.id}/test`"
              tooltip="動作確認"
              icon="mdi-beaker-check-outline"
            />
          </v-col>
          <v-col :cols="actionColumnCols">
            <template v-if="['Verified', 'Registered'].includes(item.status)">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-if="item.hidden"
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleHidden(item.id)"
                  >
                    mdi-eye-off-outline
                  </v-icon>
                  <v-icon
                    v-else
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleHidden(item.id)"
                  >
                    mdi-eye-outline
                  </v-icon>
                </template>
                <span v-if="item.hidden">公開する</span>
                <span v-else>非公開にする</span>
              </v-tooltip>
            </template>
          </v-col>
          <v-col :cols="actionColumnCols">
            <LinkIcon
              v-if="isLinked(item)"
              class="no-style-link"
              :tooltip="unlinkTooltip(item)"
              icon="mdi-link-variant-off"
              @click="displayUnlinkDialog(item)"
            />
            <LinkIcon
              v-else-if="isLinkable(item)"
              class="no-style-link"
              :tooltip="linkTooltip(item)"
              icon="mdi-link-variant"
              @click="displayLinkDialog(item)"
            />
          </v-col>
          <v-col :cols="actionColumnCols">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadConfig(item.id)"
                >
                  mdi-file-download-outline
                </v-icon>
              </template>
              <span>設定ファイルのダウンロード</span>
            </v-tooltip>
          </v-col>
          <v-col v-if="!staffSettings" :cols="actionColumnCols">
            <LinkIcon
              v-if="item.status == 'Verified'"
              class="no-style-link"
              :to="`/models/registration?base_model_id=${item.id}`"
              tooltip="新バージョンの登録"
              icon="mdi-arrow-up-bold"
            />
          </v-col>
          <v-col :cols="actionColumnCols">
            <LinkIcon
              v-if="item.is_deletable"
              class="no-style-link"
              tooltip="削除"
              icon="mdi-delete-forever"
              @click="confirmDeletion(item)"
            />
          </v-col>
        </v-row>
      </template>
      <template v-if="staffSettings" #top>
        <SearchField
          class="px-2"
          style="width: 300px;"
          @input="search = $event"
        />
      </template>
    </v-data-table>
    <v-dialog
      v-model="linkDialog"
      width="500"
    >
      <ModelLinkDialog
        :linkable-models="linkableModels"
        :origin-model="modelToLink"
        @submit="linkModels"
        @cancel="linkDialog=false"
      />
    </v-dialog>
    <v-dialog
      v-model="unlinkDialog"
      width="500"
    >
      <ModelUnlinkDialog
        :model="modelToUnlink"
        @submit="unlinkModels"
        @cancel="unlinkDialog=false"
      />
    </v-dialog>
  </div>
</template>

<script>
import consts from '@/store/consts';
import LinkIcon from '@/components/LinkIcon';
import MLMStatus from '@/components/MLMStatus';
import ModelLinkDialog from '@/components/ModelLinkDialog';
import ModelUnlinkDialog from '@/components/ModelUnlinkDialog';
import SearchField from '@/components/SearchField';
import {
  showConfirmDialog,
  showSuccessDialog,
  showErrorDialog,
  tableHeightWithMargin
} from '@/mixins/utils';
import {
  linkablePredUncertModels,
  linkableObjectiveServers
} from '@/mixins/api_utils';
export default {
  name: 'ManageableModelTable',
  components: {
    LinkIcon,
    MLMStatus,
    ModelLinkDialog,
    ModelUnlinkDialog,
    SearchField
  },
  props: {
    modules: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    staffSettings: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Type', value: 'type' },
        { text: 'Description', value: 'description' },
        { text: 'Creator', value: 'creator' },
        { text: 'Status', value: 'status' },
        { text: 'Last modification', value: 'updated_at' },
        { text: 'Action', value: 'action' }
      ],
      linkDialog: false,
      unlinkDialog: false,
      modelToLink: null,
      modelToUnlink: null,
      linkableModels: [],
      allPredictionModels: [],
      allObjectiveServers: [],
      numberDisplayActionColumn: 4,
      search: ''
    };
  },
  computed: {
    tableHeight: function() {
      // ボタン等を追加した場合、その高さを考慮する必要があります
      return tableHeightWithMargin(73);
    },
    actionColumnCols() {
      return 12 / this.numberDisplayActionColumn;
    },
    actionColumnWidth() {
      // 1つのアクション幅が24pxとなっている
      return `${24 * this.numberDisplayActionColumn}px`;
    },
    filteredHeaders() {
      return this.staffSettings
        ? this.headers
        : this.headers.filter(header => header.text !== 'Creator');
    }
  },
  mounted() {},
  methods: {
    toggleHidden(moduleId) {
      this.$emit('toggleHidden', moduleId);
    },
    isLinkable(module) {
      return ['Registered', 'Verified'].includes(module.status) &&
        consts.LinkableModelType.includes(module.type);
    },
    isLinked(module) {
      return module.mlm_os_link_id != null;
    },
    linkTooltip(module) {
      return module.type === 'Objective Server'
        ? '予測モデルに紐づける'
        : 'Objective serverに紐づける';
    },
    unlinkTooltip(module) {
      return module.type === 'Objective Server'
        ? '予測モデル紐づけ削除'
        : 'Objective server紐づけ削除';
    },
    async displayLinkDialog(module) {
      if (module) {
        this.modelToLink = module;
        if (module.type === 'Objective Server') {
          if (this.allPredictionModels.length === 0) {
            this.allPredictionModels = await linkablePredUncertModels();
          }
          this.linkableModels = this.allPredictionModels;
        } else {
          if (this.allObjectiveServers.length === 0) {
            this.allObjectiveServers = await linkableObjectiveServers();
          }
          this.linkableModels = this.allObjectiveServers;
        }
        this.linkDialog = true;
      }
    },
    displayUnlinkDialog(module) {
      if (module) {
        this.modelToUnlink = module;
        this.unlinkDialog = true;
      }
    },
    async linkModels(originId, targetId) {
      const payload = { 'module': originId, 'objective_server': targetId };
      await this.api.postModelsLink(payload, function() {
        showSuccessDialog('The models were successfully linked.');
      }, function() {
        showErrorDialog(
          "Couldn't link models.",
          'Please contact an administrator.');
      });
      this.linkDialog = false;
      this.$emit('refresh');
      // Remove linked models from the lists of linkable models
      this.allPredictionModels = this.allPredictionModels.filter(model => {
        return model.id !== originId && model.id !== targetId;
      });
      this.allObjectiveServers = this.allObjectiveServers.filter(model => {
        return model.id !== originId && model.id !== targetId;
      });
    },
    async unlinkModels(module) {
      await this.api.deleteModelsLink(module.mlm_os_link_id, function() {
        showSuccessDialog('The models were successfully unlinked.');
      }, function() {
        showErrorDialog(
          "Couldn't unlink models.",
          'Please contact an administrator.');
      });
      this.unlinkDialog = false;
      this.$emit('refresh');
    },
    downloadConfig(moduleId) {
      const self = this;
      this.api.downloadConfigFile(
        moduleId,
        function(response) {
          const name = self.modules.find(m => m.id === moduleId).name;
          self.api.downloadFileHelper(response, `${name}_config.json`, true);
        },
        function() {
          showErrorDialog(
            'An unexpected error occurred',
            `Try again or let an administrator
                        know if the problem persists.`
          );
        }
      );
    },
    confirmDeletion(module) {
      const self = this;
      showConfirmDialog({
        title: `${module.name} will be deleted.`,
        html: 'This operation cannot be undone. Are you sure you want to delete it?',
        approveCB: () => {
          self.api.deleteMLModule(
            module.id,
            function(data) {
              showSuccessDialog('', `${module.name} was deleted.`);
              self.$emit('refresh');
            },
            function(e) {
              showErrorDialog(
                'An unexpected error occurred',
                `Try again or let an administrator
                          know if the problem persists.`
              );
            }
          );
        }
      });
    }
  }
};
</script>

<style scoped>
.no-style-link {
    text-decoration: none;
}
.action-column {
  min-width: v-bind(actionColumnWidth);
}
</style>
